import api from 'services/api'
import { getApiUrlTemplateFunc } from 'helpers/getApiUrlTemplateFunc'
import { BACKEND_URL } from 'configs'

const template = getApiUrlTemplateFunc('microsoft/auth/v2')

const getIdPRedirectUrl = () => {
  return api.get(template('signin'))
}

const singInUserByToken = (idToken: string) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/x-www-form-urlencoded')
  return fetch(BACKEND_URL + '/sso/login', {
    method: 'POST',
    credentials: 'include',
    body: new URLSearchParams({ idToken }),
    headers: headers,
  })
}

const exchangeCodeForToken = (code: string) => {
  return api.post(template('code'), { code })
}

export const microsoftAuthService = {
  getIdPRedirectUrl,
  singInUserByToken,
  exchangeCodeForToken,
}
