import { UnknownQuestionCreateAPI } from 'tabs/nlp/api/types'
import { HeadersSortMap, SortConfig } from 'uiKit/table/types'
import { UserAutocomplete } from './assignUser/model/internal-types'
import { PaginationAPIResponse, PaginationConfig } from 'uiKit/table/pagination/types'

/* eslint-disable */
export enum UnknownQuestionType {
  unknown = 'unknown',
  faq = 'faq',
  beach = 'beach',
  bcp = 'bcp',
}

export type UnknownQuestionModel = {
  id: string
  question: string
  type: UnknownQuestionType
  answer?: string
  isTrained: boolean
  usersId?: string[]
  createdAt: number
  assignedUserId?: number
}

export type UnknownQuestionPaginateAPIRepsonse = PaginationAPIResponse<UnknownQuestionModel>

export type UnknownQuestionUpdateAPI = {
  question?: string
  answer?: string
  type?: UnknownQuestionType
}

export type UnknownQuestionTrainOptions = {
  followUp: boolean
}

export type UnkownQuestionsTableFields =
  | 'assignUser'
  | 'question'
  | 'answer'
  | 'train'
  | 'remove'
  | 'type'
  | 'edit'
  | 'createdAt'

export type UnkownQuestionsContextTypes = PaginationConfig & {
  unkownQuestions: UnknownQuestionModel[]
  updateType: (question: UnknownQuestionModel, type: UnknownQuestionType) => Promise<any>
  deleteQuestion: (question: UnknownQuestionModel) => Promise<any>
  trainQuestion: (question: UnknownQuestionModel, options: UnknownQuestionTrainOptions) => Promise<any>
  searchQuestions: () => Promise<any>
  updateSearchValue: (search: string) => void
  addFilterParam: (fieldName: keyof UnwQuestionFilterType, value: UnwQuestionFilterType[typeof fieldName]) => void
  search: string
  createQuestion: (
    params: UnknownQuestionCreateAPI,
    train: boolean,
    user: UserAutocomplete,
    notiry: boolean,
    withAlert: boolean,
  ) => Promise<any>
  updateQuestion: (model: UnknownQuestionModel, newQuestion: UnknownQuestionUpdateAPI) => Promise<any>
  sortHeadersMap: UnknownQuestionHeaderSortMap
  isSortingActive: boolean
  dropSortParams: () => void
  removeFilter: (field: string) => void
  loadQueryParamsInSorting: () => void
  filter: UnwQuestionFilterType
}

export type TableConfig<Titles = string> = Record<
  string,
  { maxWidth: string; title: Titles; paddingRight?: number; sort?: { descendingOrder: boolean } }
>

export type UnkownQuestionsTableHeaderTitles =
  | 'User'
  | 'Question'
  | 'Answer'
  | 'Type'
  | 'Edit'
  | 'Train'
  | 'Created At'
  | ''
  | 'User'

export type UnknownQuestionHeaderSortMap = HeadersSortMap<UnkownQuestionsTableHeaderTitles>

export type UnknownQuestionSortConfig = SortConfig<keyof UnknownQuestionModel>

export type UnwQuestionFilterType = Partial<
  Pick<UnknownQuestionModel, 'type' | 'isTrained' | 'assignedUserId' | 'createdAt'>
> & {
  isAnswerExists: boolean
}
