export default {
  container: provided => ({
    ...provided,
    width: '100%',
    maxWidth: '300px',
  }),
  control: provided => ({
    ...provided,
    border: '1px solid var(--color-issabeline-grey)',
    borderRadius: 10,
    outline: 'none',
    boxShadow: 'none',
    width: '100%',
    height: 45,
    '&:hover': {
      border: '1px solid var(--color-issabeline-grey)',
      boxShadow: 'none',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'var(--color-text-secondary)',
    fontSize: 14,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    cursor: 'pointer',
    backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
    color: state.children === '+ Create new' ? 'var(--color-button-secondary)' : 'var(--color-text-primary)',
    '&:hover': {
      fontSize: 14,
      backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
      color: state.children === '+ Create new' ? 'var(--color-button-secondary)' : 'var(--color-text-primary)',
    },
  }),
  menu: provided => ({
    ...provided,
    wordBreak: 'break-all',
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: 14,
  }),
}
