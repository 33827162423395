import React from 'react'

import { KnowledgeBaseSubTabMap } from 'tabs/nlp/constants'

import * as S from './RadioButtonGroup.style'
import { CogIcon } from '../../../../../uiKit/icons/Icons'

const options = [
  {
    label: 'Website',
    value: KnowledgeBaseSubTabMap.WEBSITE,
  },
  {
    label: 'File',
    value: KnowledgeBaseSubTabMap.FILE,
  },
  {
    label: 'Text',
    value: KnowledgeBaseSubTabMap.TEXT,
  },
  // {
  //   label: 'Settings',
  //   value: KnowledgeBaseSubTabMap.SETTINGS,
  // },
]

interface Props {
  value: string
  onChange: (a: string) => void
}

const handleSettingsClick = (value: string) => {
  if (value === KnowledgeBaseSubTabMap.SETTINGS) {
    return 'var(--color-text-on-secondary)'
  }
  return 'var(--color-text-secondary)'
}

export const RadioButtonGroup: React.FC<Props> = ({ value, onChange }) => {
  return (
    <S.RadioButtonGroup>
      {options.map(option => (
        <S.Option key={option.value} isActive={option.value === value} onClick={() => onChange(option.value)}>
          {option.value !== KnowledgeBaseSubTabMap.SETTINGS ? (
            option.label
          ) : (
            <CogIcon width={40} height={20} color={handleSettingsClick(value)} />
          )}
        </S.Option>
      ))}
    </S.RadioButtonGroup>
  )
}
