import { PayloadAction } from 'tabs/topQuestions/types/GenericTypes'

export const POWERBI_CONFIG_GET = 'powerBi/config/get'
export const POWERBI_CONFIG_UPDATE = 'powerBi/config/update'

export type PowerBiConfigGet = PayloadAction<typeof POWERBI_CONFIG_GET, any>
export type PowerBiConfigUpdate = PayloadAction<typeof POWERBI_CONFIG_UPDATE, PowerBiTokenStatus>

export type PowerBiConfigActions = PowerBiConfigGet | PowerBiConfigUpdate

export type PowerBiTokenStatus = {
  isWaitingForResponse: boolean
}

export const powerBiConfigGet = () => ({
  type: POWERBI_CONFIG_GET,
  payload: undefined,
})

export const powerBiConfigUpdate = (status: boolean) => ({
  type: POWERBI_CONFIG_UPDATE,
  payload: { isWaitingForResponse: status },
})

export const powerBiConfigReduser = (state = { isWaitingForResponse: false }, action: PowerBiConfigActions) => {
  switch (action.type) {
    case POWERBI_CONFIG_GET:
      return state
    case POWERBI_CONFIG_UPDATE:
      return {
        ...state,
        isWaitingForResponse: action.payload.isWaitingForResponse,
      }
    default:
      return state
  }
}

export const getPowerBiStatus = (store): boolean => {
  return store.powerBiConfigReduser.isWaitingForResponse
}
