import React, { useState, useRef } from 'react'

import LoaderOverlay from 'uiKit/loaders/loaderOverlay'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import { alertError, alertSuccess } from 'api'

import * as S from './PowerBiTokens.style'
import { getMicrosoftIdentityProviderUrl, waitingOnMicrosoftAuthStatus } from 'tabs/settings/api/powerBiTokens'

interface Props {
  activeBot: any
  isExpanded: boolean
}

export const PowerBITokens: React.FC<Props> = ({}) => {
  const [loading, setLoading] = useState(false)
  const containerEndRef = useRef(null)

  const handleSubmit = () => {
    setLoading(true)
    waitingOnMicrosoftAuthStatus()
      .then(() => {
        alertSuccess('Tokens acquired successfully!')
      })
      .catch(() => alertError('Fail. Try it later'))
      .finally(() => setLoading(false))

    getMicrosoftIdentityProviderUrl()
      .then(url => {
        window.open(url, '_blank')
      })
      .catch(error => alertError(error?.message || 'Fail. Try it later'))
  }

  return (
    <>
      <S.ButtonWrap>
        <SubmitButton title="Acquire tokens" type={'secondary'} size="sm" onClick={handleSubmit} disabled={loading} />
      </S.ButtonWrap>
      {loading && <LoaderOverlay />}
      <div ref={containerEndRef} />
    </>
  )
}
