import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { TestWidgetMessengerMenu } from '../../../tabs/settings/components/integrations/TestWidgetMessengerMenu'

const TestButtons = ({ activeBot, classes }) => {
  if (!activeBot) {
    return null
  }
  const showMessengerButton = activeBot.messengerConfigs && activeBot.messengerConfigs.pageId
  const showAiChatButton = activeBot.odinConfigs?.projectId

  return (
    <div className="d-flex gap-3 me-2">
      {/* <TestWidgetMessengerMenu botId={activeBot.id} /> */}
      {/* {showMessengerButton && <MessengerDemoButton activeBot={activeBot} classes={classes} />} */}
      {/* {showAiChatButton && <AiChatDemoButton activeBot={activeBot} classes={classes} />} */}
      {/* <WidgetDemoButton activeBot={activeBot} classes={classes} /> */}
    </div>
  )
}

const MessengerDemoButton = ({ activeBot, classes }) => {
  return (
    <a
      style={{ textDecoration: 'none' }}
      href={`https://www.messenger.com/t/${activeBot.messengerConfigs.pageId}`}
      target="_blank"
      rel="noreferrer">
      <TestButton title="Test in Messenger" classes={classes} />
    </a>
  )
}

const AiChatDemoButton = ({ activeBot, classes }) => {
  return <DemoButton title="Test AI chat" link={`/ai-chat-demo/${activeBot?.publicIdentifier}`} classes={classes} />
}

const WidgetDemoButton = ({ activeBot, classes }) => {
  return (
    <DemoButton
      title="Test widget"
      link={`/widget-demo/${activeBot?.publicIdentifier}?isTestMode=true`}
      classes={classes}
    />
  )
}

const DemoButton = ({ title, link, classes }) => {
  return (
    <Link style={{ textDecoration: 'none' }} to={link} target="_blank" rel="noreferrer">
      <TestButton title={title} classes={classes} />
    </Link>
  )
}

const TestButton = ({ title, classes }) => {
  return (
    <Button
      style={{
        background: 'var(--color-button-primary)',
        boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
        borderRadius: 10,
        height: 45,
      }}>
      <p className={classes.testButton}>{title}</p>
    </Button>
  )
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(TestButtons)
