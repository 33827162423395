/**
 * Created by Admin on 26.02.2018.
 */
import React from 'react'
import { ListItemIcon } from '@material-ui/core'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { RouteConfiguration } from 'routes/nlpRoutes'
import { StyledComponentProps } from '@material-ui/core/styles/withStyles'
import ListItemText from '@mui/material/ListItemText'
import './styles.scss'

type SubmenuProps = {
  pathLink: string
  pathRoute: string
  routes: RouteConfiguration[]
}

export default function Submenu(props: StyledComponentProps & SubmenuProps) {
  return (
    <div className={'submenuWrapper'}>
      <div className={'submenuContainer'}>
        <div className={'submenuNavigationWrapper'}>
          <div className={'submenuNavigation'}>
            <List className={'submenuRootLink'}>
              {props.routes.map(route => (
                <NavLink
                  className={'submenuLink'}
                  key={route.path}
                  activeClassName={'submenuActiveLink'}
                  to={`${props.pathLink}/${route.path}`}>
                  <ListItem button className={'submenuListItem'}>
                    <ListItemText className={'submenuLinkText'} primary={route.name} />
                    <ListItemIcon style={{ marginRight: 0 }}>{route.icon()}</ListItemIcon>
                  </ListItem>
                </NavLink>
              ))}
            </List>
          </div>
        </div>
        <div className={'submenuBody'}>
          <Switch>
            {props.routes.map(({ path, component: Component }) => (
              <Route
                exact
                key={path}
                path={`${props.pathRoute}${path}/:sourceType?/:sourceId?`}
                render={() => {
                  return <Component />
                }}
              />
            ))}
            <Redirect from={props.pathRoute} to={`${props.pathLink}/${props.routes[0].path}`} />
          </Switch>
        </div>
      </div>
    </div>
  )
}
