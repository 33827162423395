import api from 'services/api'
import { getApiUrlTemplateFunc } from 'helpers/getApiUrlTemplateFunc'

const template = getApiUrlTemplateFunc('microsoft/auth/v2/code')

export const getMicrosoftIdentityProviderUrl = () => {
  return api.get(template('signin'))
}

export const waitingOnMicrosoftAuthStatus = () => {
  return api.get(template('status'))
}
