import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { microsoftAuthService } from '../api/microsoftAuth'
import { HOME_PATH } from 'configs'
import { getPowerBiStatus, powerBiConfigUpdate } from '../actions/authCode'
import { useDispatch, useSelector } from 'react-redux'

export const MicrosoftAuthRedirectHandler = props => {
  const { location, security } = props
  const isWaitingForResponse = useSelector(getPowerBiStatus)
  const dispatch = useDispatch()

  const handleSSO = (idToken: string) => {
    return microsoftAuthService.singInUserByToken(idToken).then(response => {
      if (response.ok) props.authenticateWithReferrer({ pathname: HOME_PATH + '/bot/1/nlp/ai-knowledge' })
    })
  }

  const handleCodeFlow = (code: string) => {
    dispatch(powerBiConfigUpdate(true))
    return microsoftAuthService.exchangeCodeForToken(code).finally(() => {
      dispatch(powerBiConfigUpdate(true))
      window.close()
    })
  }

  useEffect(() => {
    const hashParams = new URLSearchParams(location.hash.substring(1))
    const idToken = hashParams.get('id_token')
    if (!security?.authenticated && idToken) handleSSO(idToken)

    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get('code')
    if (!isWaitingForResponse && security?.authenticated && code) handleCodeFlow(code)
  }, [])

  return null
}

export default withRouter(MicrosoftAuthRedirectHandler)
