import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  tableRow: {
    // "&:hover": {
    //     border: "0.5px solid var(--color-button-primary)",
    //     borderRadius: 10,
    //     padding: 19,
    // },
    minHeight: 80,
    padding: 20,
    fontSize: 14,
    display: 'flex',
    fontFamily: 'Lato, sans-serif',
    color: 'var(--color-text-secondary)',
    justifyContent: 'space-between',
    gap: '30px',
  },
})

class TableRow extends React.Component {
  render() {
    const { classes, style } = this.props

    return (
      <div
        style={
          (this.props.index + 1) % 2 === 1
            ? { borderRadius: 10, ...style }
            : {
              background: 'var(--color-table-row-primary)',
              borderRadius: 10,
              ...style,
            }
        }
        onClick={this.props.onClick}
        className={classes.tableRow}>
        {this.props.children}
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(TableRow)
